"use strict";

const bodyID = document.querySelector(".body");

// Navbar render
const navbarMarkup = function () {
  return /*html*/ `
<nav id="navbar" class="navbar">
    <a href="./index.html">
        <div class="logo">
            </div
        >
    </a>
    <div>
        <ul class="menu--inline">
            <li class="main--li"><a href="./index.html">Home</a></li>
            <li class="main--li"><a href="./news.html">News</a></li>
            <li class="main--li squadre--inline has--submenu">
                <a>Squadre</a>
                <ul class="ul--squadre--inline submenu">
                    <li class="sub--li">
                        <a href="./prima-squadra.html">Prima Squadra</a>
                    </li>
                    <li class="sub--li"><a href="./allievi.html">Allievi Prov. U17</a></li>
                    <li class="sub--li"><a href="./allievi2.html">Allievi Prov. U17 sq.B</a></li>
                    <li class="sub--li">
                        <a href="./giovanissimi.html">Giovanissimi Reg. U15</a>
                    </li> 
                    <li class="sub--li rosa">
                        <a href="https://www.tuttocampo.it/Veneto/PrimaCategoria/GironeH/Squadra/SanStino/920569/Rosa"
                           target="_blank">Rosa Tuttocampo</a>
                    </li>
                </ul>
            <li class="main--li"><a href="./gallery.html">Gallery</a></li>
            <li class="main--li"><a href="./storia.html">Storia del Club</a></li>
            <li class="main--li"><a href="./sponsor.html">Sponsor</a></li>
            <li class="main--li"><a href="./contatti.html">Contatti</a></li>
        </ul>
    </div>
    <div class="menu-wrap">
        <div class="hamburger">
            <div class="line line--1"></div>
            <div class="line line--2"></div>
            <div class="line line--3"></div>
        </div>
    </div>
</nav>
<div class="menu hidden">
    <div>
        <div>
            <ul class="menu--ul">
                <li><a href="./index.html">Home</a></li>
                <li><a href="./news.html">News</a></li>
                <li>
                    <a class="squadre"
                    >Squadre <span class="arrow--down">&#x25BC;</span
                    ><span class="arrow--up display--none">&#9650;</span></a
                    >
                    <ul class="ul--squadre">
                          <li class="sub--li">
                        <a href="./prima-squadra.html">Prima Squadra</a>
                    </li>
                    <li class="sub--li"><a href="./allievi.html">Allievi Prov. U17</a></li>
                    <li class="sub--li"><a href="./allievi2.html">Allievi Prov. U17 sq.B</a></li>
                    <li class="sub--li">
                        <a href="./giovanissimi.html">Giovanissimi Reg. U15</a>
                    </li> 
                    </ul>
                </li>
                <li><a href="./gallery.html">Gallery</a></li>
                <li><a href="./storia.html">Storia del Club</a></li>
                <li><a href="./sponsor.html">Sponsor</a></li>
                <li><a href="./contatti.html">Contatti</a></li>
            </ul>
        </div>
    </div>
</div>
    `;
};

const navMarkup = navbarMarkup();
bodyID.insertAdjacentHTML("afterbegin", navMarkup);

/////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// Footer Render /////////////////////////////////////
const footer = document.querySelector("#footer");

const footerMarkup = function () {
  return /*html*/ `
  <div class="social">
        <a href="https://www.facebook.com/acdsanstino" target="_blank"
        ><i class="fab fa-brands fa-facebook fa-2x"></i
        ></a>
        <a href="https://www.instagram.com/acd_sanstino/" target="_blank"
        ><i class="fab fa-brands fa-instagram fa-2x"></i
        ></a>
    </div>
    <div class="preferenze-cookie">
        <a href="#" class="iubenda-cs-preferences-link">Aggiorna le tue preferenze di tracciamento della pubblicità</a>
    </div>
    <div class="privacy-policy">
        <div class="policy-title">
            <a href="https://www.iubenda.com/privacy-policy/53613718"
               class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
               title="Privacy Policy ">Privacy Policy</a>
            <script type="text/javascript">(function (w, d) {
                var loader = function () {
                    var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
                    s.src = "https://cdn.iubenda.com/iubenda.js";
                    tag.parentNode.insertBefore(s, tag);
                };
                if (w.addEventListener) {
                    w.addEventListener("load", loader, false);
                } else if (w.attachEvent) {
                    w.attachEvent("onload", loader);
                } else {
                    w.onload = loader;
                }
            })(window, document);</script>
        </div>
        <div class="policy-title">
            <a href="https://www.iubenda.com/privacy-policy/53613718/cookie-policy"
               class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie
                Policy</a>
            <script type="text/javascript">(function (w, d) {
                var loader = function () {
                    var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
                    s.src = "https://cdn.iubenda.com/iubenda.js";
                    tag.parentNode.insertBefore(s, tag);
                };
                if (w.addEventListener) {
                    w.addEventListener("load", loader, false);
                } else if (w.attachEvent) {
                    w.attachEvent("onload", loader);
                } else {
                    w.onload = loader;
                }
            })(window, document);</script>
        </div>
    </div>
    <div class="last--row">
        <div class="developer">
            <a href="mailto:davide.mulato@gmail.com">
                <i class="fas fa-solid fa-desktop"></i>Davide Mulato</a
            >
        </div>
        <p>ACD San Stino &copy; 2022, All Rights Reserved</p>
    </div>
  `;
};

const footMarkup = footerMarkup();
footer.insertAdjacentHTML("afterbegin", footMarkup);
